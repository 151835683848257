@font-face {
    font-family: 'Material Symbols Rounded';
    src: url('./fonts/material-symbols-rounded.woff2') format('woff2');
    font-display: block;
}
@font-face {
    font-family: 'Material Symbols Rounded Filled';
    src: url('./fonts/material-symbols-rounded-filled.woff2') format('woff2');
    font-display: block;
}
.material-symbols-rounded {
    font-family: 'Material Symbols Rounded';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
}
.material-symbols-rounded.filled {
    font-family: 'Material Symbols Rounded Filled';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
}
.material-symbols-rounded-filled.smoothed, .material-symbols-rounded.smoothed {
    -webkit-font-smoothing: antialiased;
}
.material-symbols-rounded-filled.md-16, .material-symbols-rounded.md-16 {
    font-size: 16px;
}
.material-symbols-rounded-filled.md-20, .material-symbols-rounded.md-20 {
    font-size: 20px;
}
.material-symbols-rounded-filled.md-24, .material-symbols-rounded.md-24 {
    font-size: 24px;
}
.material-symbols-rounded-filled.md-28, .material-symbols-rounded.md-28 {
    font-size: 28px;
}
.material-symbols-rounded-filled.md-32, .material-symbols-rounded.md-32 {
    font-size: 32px;
}
.material-symbols-rounded-filled.md-36, .material-symbols-rounded.md-36 {
    font-size: 36px;
}
.material-symbols-rounded-filled.md-40, .material-symbols-rounded.md-40 {
    font-size: 40px;
}
.material-symbols-rounded-filled.md-48, .material-symbols-rounded.md-48 {
    font-size: 48px;
}
