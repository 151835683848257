@import './fonts/material-symbols/fonts.css';
@import './fonts/Lato/lato.css';

body {
    font-family: 'Lato', sans-serif !important;
}


.header-title .hidden-sort-icon {
    display: none;
    margin-left: 4px;
    width: 24px;
    height: 24px;
}

.header-title:hover {
    color: rgba(0, 0, 0, 0.54);
}

.header-title:hover .hidden-sort-icon {
    display: block !important;
}

.active-sort-icon {
    margin-left: 4px;
    width: 24px;
    height: 24px;
}

.header-title-active-sort {
    color: #0E141A !important;
}

.MuiPaper-root.MuiPaper-elevation0.MuiPaper-rounded>div:nth-child(2) {
    overflow-x: unset !important;
}

#html-editor-container.invalid .ql-container.ql-snow {
    border-color: red !important;
}

#html-editor-container.invalid .ql-toolbar.ql-snow {
    border-color: red !important;
}

#html-editor-container .ql-toolbar.ql-snow {
    border-bottom: none !important;
}

#html-editor-container-redesign.invalid .ql-container.ql-snow {
    border-color: red !important;
}

#html-editor-container-redesign.invalid .ql-toolbar.ql-snow {
    border-color: red !important;
}

#html-editor-container-redesign .ql-toolbar.ql-snow {
    border-bottom: none !important;
    border: none !important;
    padding: 6px 0px !important;
}

#html-editor-container-redesign .ql-toolbar.ql-snow button {
   padding: 3px 0px;
}

#html-editor-container-redesign .ql-container.ql-snow {
    border-radius: 5px !important;
    min-height: 300px !important;
    resize: vertical;
    overflow: hidden;
    font-size: 16px;
}

#html-editor-container-redesign .ql-editor {
    padding: 5px 8px !important;
}



/* variant import modal */

progress {
    appearance: none;
    /* Remove the default styles */
    border: 0;
    opacity: 0.7;
    border-radius: 10px !important;
}

/* Set the color of the progress bar when it's filled */
progress::-webkit-progress-value {
    border: 0;
    height: 10px;
    background-color: #2CAE44;
    border-radius: 10px;
    /* Change the color to blue */
}

progress::-webkit-progress-bar {
    border: 0;
    height: 8px;
    border-radius: 20px;
}
progress::-moz-progress-bar {
    border: 0;
    height: 8px;
    border-radius: 20px;
}


/* varaint table redesing */

.black-button button {
    background-color: #18222B;
    border-color: #18222B !important;
    color: white;
    width: 118px;
}

.tabs-border {
    flex: 1;
    border-bottom: 1px solid !important;
    border-color: #FC780B !important;
    margin: 0 20px 8px 0
}

.issue-selector-container {
    .variant-issue-selector-heading-text {
        color: black;
        font-weight: 200;
        font-size: 20px;
    }

    .variant-issue-selector-description {
        color: #FC780B;
        text-decoration: underline;
        font-size: 16px;
        font-weight: 400;
    }

    .heading-icon-black {
        color: black;
    }
}

.table-container-black {
    

    .focused-input-field:focus {
        border: 2px solid #FC780B !important;
        outline: none;
    }

    .currency-format {
        color: black !important
    }

    .currency-format:focus {
        border: 2px solid #FC780B !important;
        outline: none;
    }

    .variant-checbox {
        background-color: #F7991C !important;
    }

    .warning-td {
        border-top: 1px solid #C1C9D1 !important;
        background-color: #FFCB93 !important;
    }
    td {
        border-left: none;
        border-right: none;
    }

    .primary-td{
        /* border-top: 3px solid #FFCB93 !important;
        border-bottom: 3px solid #FFCB93 !important; */
        border-width: 1px;
    }

    .th-parent{
        border-left: 1px solid #E1E6EB !important;
        border-top-left-radius: 10px;
    }

    .th-parent-right{
        border-right: 1px solid #E1E6EB !important;
        border-top-right-radius: 10px;
    }

    .th-child{
        border-left: 1px solid #E1E6EB !important;
    }

    .th-child-right{
        border-right: 1px solid #E1E6EB !important;
    }

    .validation-warning-td{
        border-right: 1px solid #FFCB93 !important;
    }

    .hidden-tr {
        border: 1px solid #FFCB93 !important;
    }

}

.align-checkbox {
    margin-top: none !important;
}

.react-tagsinput-redesign {
    span {
      div:after {
        content: ' ';
      }

      div:last-of-type:after {
        content: ' ';
      }
    }

    .MuiSvgIcon-root {
        cursor: pointer;
    }
  }  

.warehouse-select-box{
   text-align: start;

   input {
    margin: 0px !important;
   }
}

.logistics-warehouse{
    max-height: 270px;
    overflow: auto;
    margin-bottom: 10px;
    .warehouse-name{
        margin: 5px 0px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
    }
}

.no-warehouse-container{
    font-size: 14px;
    font-weight: bolder;
    color: rgb(180, 180, 180);
    margin:10px 0px
}

.mry-5{
    margin:10px 0px
}


.warehouse-container{
    height: 400px;
    overflow: auto;
}

.pointer{
    cursor: pointer;
}

.searchContainer {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #FFF;
    border: 1px solid #C1C9D1;
    border-radius: 6px;
    height: 36px;

    .search-bar {
        width: 200px;
        height: 100%;
        min-width: 200px;
        border-left: none;
        border-right: none;
    }
    .search-bar:focus {
        border-left : none ;
        border-right : none ;
        outline: none;
    }
}


.multiSelect-container{
    width: 220px;


   .selection-label{
    text-transform: capitalize;
   }

    .MuiInput-underline::before{
        border-bottom: none !important;
    }
    .MuiInput-underline::after{
        border-bottom: none !important;
    }

    .MuiSelect-select.MuiSelect-select{
        background-color: #FFF !important;
    }

   

    .select-field{
        background-color: #FFF !important;
        border-radius: 10px !important;
        border: 1px solid #C1C9D1 !important;
        box-shadow: none !important;
        height: 40px;
        padding: 10px;
        .MuiInput-input{
            width: 230px;
            max-width: 230px;
        }
    }

    .select-field .MuiInputBase-root {
        border: none !important;
        box-shadow: none !important;
    }
    
    .select-field .MuiInput-underline:before,
    .select-field .MuiInput-underline:after {
        border-bottom: none !important;
    }
}

.Mui-checked{
    color: #F7991C !important ;
}

.MuiMenu-list{
    max-height: 300px;
}

.column-settings-container{
    margin: 5px -15px !important;
    div {
        ul {
            max-height: 700px !important;
        }
    }
}

.Mui-selected{
    background-color: white !important;
}

.list-text{
    text-wrap: wrap;
}

.no-warehouse-box{
    border: 1px solid #C1C9D1;
    padding: 20px;
    color:  #53595E;
    margin-top: 10px;
}

.disabled-btn{
    cursor: default !important;
    opacity: 0.7;
}

.play-video-modal {
    z-index: 500 !important;
}

.search-bar{
    border-color: #C1C9D1 !important;
}

.error-field{
    border: 2px solid #C82135 !important;
}
.red-label{
    color: #C82135 !important ;
}

.error-badge{
    margin: 5px 5px;
    padding: 2px;
    color: white ;
    background-color: #FC780B ;
    border-radius: 20%;
}

.cursor-default{
    cursor: default !important;
}

.mr-5{
    margin-right: 15px;
}

.select-menu-item {
    padding: 10px 10px 5px 10px !important;
}

.select-menu-parent {
    margin: 5px -10px !important;
    max-height: 230px !important;
    max-width: 200px !important;
    min-width: auto !important;
}

.multiSelect-container {
    & .select-field {
        .MuiInput-input {
            width: 100%;
            max-width: 100%;
        }
    }
}

.MuiTabs-scroller {
    overflow: auto !important;
}